<template>
	<div id="monitoringList">
		<div class="row justify-content-center">
			<div class="col-md-10">
				<!-- Monitoring page header -->
				<div class="monitoring-header">
					<div class="actions-left">
						<router-link
							v-if="!loading && monitorings.length < 5"
							class="btn btn-outline-primary"
							to="/monitoring/create"
							title="	Add new monitoring item"
							><font-awesome-icon :icon="['fas', 'plus-circle']" /> Add monitoring</router-link
						>
					</div>
					<div class="center-image">
						<img src="../../../public/images/monitoring.svg" width="180" alt="" />
					</div>
				</div>

				<div class="card">
					<div class="card-body" v-if="!loading">
						<div class="d-flex justify-content-between align-items-center" v-if="monitorings.length > 0">
							<p v-if="monitorings.length < 5">
								👍 You have {{ 5 - monitorings.length }} monitoring left
							</p>

							<p v-else class="">
								😔 You reach the limit of monitoring items
							</p>

							<button
								v-if="display === 'block'"
								@click="changeView('list')"
								title="List view"
								class="btn btn-sm btn-primary"
							>
								<font-awesome-icon :icon="['fas', 'list']" />
							</button>
							<button
								v-else
								class="btn btn-sm btn-primary"
								@click="changeView('block')"
								title="Block view"
							>
								<font-awesome-icon :icon="['fas', 'th-large']" />
							</button>
						</div>
						<div v-else class="d-flex justify-content-center align-items-center flex-column">
							<h6>You don't have any monitor click add new item to add it</h6>
							<router-link class="btn btn-outline-primary btn-sm" to="/monitoring/create"
								>+ Add monitoring</router-link
							>
						</div>

						<div class="monitorings">
							<div v-if="display === 'block'" class="block-monitorings">
								<div class="row py-3" v-if="monitorings.length > 0">
									<div
										v-for="monitoring in monitorings"
										:key="monitoring.id"
										class="col-xs-4 col-lg-4 col-md-6 mt-3"
									>
										<button
											@click="deleteMonitoring(monitoring)"
											class="btn btn-sm text-danger"
											style="margin-bottom:-50px"
										>
											<font-awesome-icon :icon="['fas', 'times']" />
										</button>
										<div
											class="monitoring-item p-3 border-light shadow text-center rounded"
											:class="monitoring.status"
										>
											<b class="cut-text"> {{ monitoring.name }}</b> <br />
											<a :href="monitoring.url" class="cut-text" target="_blank">
												<code>{{ monitoring.url }}</code>
											</a>
											<div>
												<code>{{ monitoring.check_date }}</code>
											</div>
											<div>
												<router-link
													class="btn btn-primary btn-sm mt-3"
													:to="'/monitoring/view/' + monitoring.id"
													>See details</router-link
												>
												<button
													class="btn btn-outline-secondary rounded-circle btn-sm  mt-3 ms-1"
													@click="checkNow(monitoring.url, monitoring.id)"
													title="Check now"
												>
													<font-awesome-icon
														:icon="['fas', 'sync']"
														:class="loadingSingle && loadingSingleId == monitoring.id ? 'fa-spin':''"
													/>
												</button>
											</div>
										</div>
										<button v-if="false" @click="getScreenshot(monitoring.url)">Screenshot</button>
									</div>
								</div>
							</div>

							<div v-else class="list-monitorings">
								<table v-if="monitorings.length > 0" class="table lp-table table-hover mt-3">
									<thead>
										<tr>
											<th>Name</th>
											<th>Url</th>
											<th>Last check</th>
											<th>Last offline</th>
											<th>Actions</th>
										</tr>
									</thead>

									<tbody>
										<template>
											<tr
												v-for="monitoring in monitorings"
												:key="monitoring.id"
												:class="monitoring.status"
											>
												<td @click="monitoringEdit(monitoring)" role="button">
													{{ monitoring.name }}
												</td>
												<td>
													<a :href="monitoring.url" target="_blank">{{ monitoring.url }}</a>
												</td>

												<td @click="monitoringEdit(monitoring)" role="button">
													{{ monitoring.check_date }}
												</td>
												<td @click="monitoringEdit(monitoring)" role="button">
													{{ monitoring.last_offline_at }}
												</td>
												<td>
													<router-link
														class="btn text-success btn-sm me-1"
														:to="'/monitoring/view/' + monitoring.id"
														title="View monitoring"
													>
														<font-awesome-icon :icon="['fas', 'eye']" />
													</router-link>
													<button
														class="btn text-info btn-sm me-1"
														@click="checkNow(monitoring.url, monitoring.id)"
														title="Check now"
													>
														<font-awesome-icon
															:icon="['fas', 'sync']"
															:class="loadingSingle && loadingSingleId == monitoring.id ? 'fa-spin':''"
														/>
													</button>

													<button
														class="btn text-danger btn-sm"
														@click="deleteMonitoring(monitoring)"
														title="Delete monitoring"
													>
														<font-awesome-icon :icon="['fas', 'times']" />
													</button>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div v-else class="card-body">
						<div class="d-flex justify-content-center">
							<div class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	data() {
		return {
			loadingSingle: false,
			loadingSingleId: 0,
			monitorings: [],
			display: localStorage.getItem('monitoringDisplay') || 'block',
			loading: false,
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},

	created() {
		this.getMonitorings()
		const timer = setInterval(() => {
			this.getMonitoringsSilent()
		}, 30000)
		this.$once('hook:beforeDestroy', () => {
			clearInterval(timer)
		})
	},

	methods: {
		// Get monitorings
		getMonitorings() {
			this.loading = true
			restApi
				.get('/monitorings')
				.then(({ data }) => {
					this.monitorings = data
					this.loading = false
				})
				.catch(error => {
					console.log(error)
				})
		},

		// Update monitoring data silently
		getMonitoringsSilent() {
			restApi
				.get('/monitorings')
				.then(({ data }) => {
					this.monitorings = data
				})
				.catch(error => {
					console.log(error)
				})
		},

		// Edit monitoring
		monitoringEdit(monitoring) {
			this.$router.push('/monitoring/view/' + monitoring.id)
		},

		// Change display view of monitoring
		changeView(type) {
			this.display = type
			localStorage.setItem('monitoringDisplay', type)
		},

		checkNow(url, id) {
			this.loadingSingle = true
			this.loadingSingleId = id
			restApi
				.post('/helpers/check', { url: url, id: id })
				.then(({ data }) => {
					this.updateCheckedMonitoring(id, data.monitoring)
					this.loadingSingle = false
					this.loadingSingleId = 0
				})
				.catch(error => {
					this.loadingSingle = false
					this.loadingSingleId = 0
					console.log(error)
				})
		},

		// Update monitorings in state
		updateCheckedMonitoring(id, monitoring) {
			this.monitorings.forEach(m => {
				if (m.id === id) {
					m.check_date = monitoring.check_date
					m.status = monitoring.status
				}
				return m
			})
		},

		getScreenshot(url) {
			restApi
				.post('/helpers/screenshot', { url: url })
				.then(({ data }) => {
					console.log('here')
					console.log(data)
				})
				.catch(error => {
					console.log(error)
				})
		},

		// Delete monitoring
		deleteMonitoring(monitoring) {
			this.$swal
				.fire({
					title: 'Are you sure?',
					text: `You won't be able to revert this! `,
					icon: 'warning',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!',
				})
				.then(result => {
					if (result.isConfirmed) {
						restApi
							.delete(`/monitorings/` + monitoring.id)
							.then(() => {
								this.monitorings = this.monitorings.filter(m => m.id !== monitoring.id)
								this.$router.push(`/monitoring`)
								this.$swal.fire({
									icon: 'success',
									title: `Monitoring ${monitoring.name} successfully deleted!`,
								})
							})
							.catch(error => {
								this.$swal.fire({
									icon: 'error',
									title: handleErrors(error, ''),
									timer: 6000,
								})
							})
					}
				})
		},
	},
}
</script>
