<template>
	<div id="monitoringCreate">
		<div class="row justify-content-center">
			<div class="col-md-8">
				<!-- Monitoring page header -->
				<div class="monitoring-header">
					<div class="actions-left">
						<router-link class="btn btn-outline-primary" to="/monitoring">
							<font-awesome-icon :icon="['fas', 'arrow-left']" /> Back</router-link
						>
					</div>
					<div class="center-image">
						<img src="../../../public/images/monitoring-create.svg" width="220" alt="" />
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="text-center">
							<h4 class="p-0 m-0">Create new monitoring</h4>
							<small>Create new monitoring with settings that suit you</small>
						</div>

						<form @submit.prevent="monitoringCreate" class="mt-4">
							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Name</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="newMonitoring.name"
										class="form-control"
										id="name"
										required
									/>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">URL</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="newMonitoring.url"
										class="form-control"
										id="name"
										required
									/>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Check interval</label>
								<div class="col-sm-6">
									<select v-model="newMonitoring.check_interval" class="form-select" required>
										<option disabled value="5">5 minutes (coming soon)</option>
										<option value="10">10 minutes</option>
										<option value="20">20 minutes</option>
										<option value="30">30 minutes</option>
									</select>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Check type</label>
								<div class="col-sm-6">
									<select v-model="newMonitoring.check_type" class="form-select" required>
										<option value="ping">Ping (0/5)</option>
										<option value="website">Website (0/3)</option>
									</select>
								</div>
							</div>

							<div class="mb-3 row" v-if="newMonitoring.check_type === 'website'">
								<label for="email" class="col-sm-3 col-form-label">Website search</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="newMonitoring.website_search_string"
										class="form-control"
									/>
									<small>Enter string that you wanna search on page</small>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label pt-0">Notifications</label>
								<div class="col-sm-9">
									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="newMonitoring.email_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
										/>
										<label for="" class="form-check-label ms-2">Email</label>
									</div>

									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="newMonitoring.slack_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
											disabled
										/>
										<label for="" class="form-check-label ms-2"
											>Slack <small>(coming soon)</small></label
										>
									</div>

									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="newMonitoring.sms_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
											disabled
										/>
										<label for="" class="form-check-label ms-2"
											>Text message <small>(coming soon)</small></label
										>
									</div>
								</div>
							</div>

							<div v-if="!disableCreating" class="mt-4 float-end">
								<button v-if="!loading" class="btn btn-primary">Create monitoring</button>
								<button v-else class="btn btn-primary" type="button" disabled>
									<span
										class="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
									Checking status...
								</button>
							</div>
							<div v-else class="text-center">
								😔 You reach the limit of monitoring items for your package
								You can upgrade your package here
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'MonitoringCreate',
	data() {
		return {
			loading: false,
			monitoringsCount: 0,
			monitoringsLimit: 5,
			disableCreating: false,
			newMonitoring: {
				name: '',
				url: '',
				check_interval: 10,
				check_type: 'ping',
				website_search_string: '',
				email_notification: true,
				slack_notification: false,
				sms_notification: false,
			},
		}
	},
	watch:{
		monitoringsCount: function(){
			if(this.monitoringsCount >= this.monitoringsLimit){
				this.disableCreating = true
			}else{
				this.disableCreating = false
			}
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},
	mounted(){
		this.getMonitorings()
	},
	methods: {
		// Get monitorings
		getMonitorings() {
			this.loading = true
			restApi
				.get('/monitorings')
				.then(({ data }) => {
					this.monitoringsCount = data.length
					console.log(this.monitoringsCount)
					this.loading = false
				})
				.catch(error => {
					console.log(error)
				})
		},
		monitoringCreate() {
			this.loading = true

			restApi
				.post('/monitorings', this.newMonitoring)
				.then(() => {
					this.loading = false
					this.$router.push({ name: 'Monitoring' })
					this.$swal.fire({
						icon: 'success',
						title: 'Monitoring successfully created!',
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error),
						timer: 5000,
					})
				})
		},
	},
}
</script>
