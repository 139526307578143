<template>
	<div id="monitoringCreate">
		<div class="row justify-content-center">
			<div class="col-md-8">
				<div class="row mt-4">
					<div class="col-md-4 d-flex justify-content-start align-items-center">
						<router-link class="btn btn-outline-primary" :to="'/monitoring/view/' + $route.params.id">
							<font-awesome-icon :icon="['fas', 'arrow-left']" /> Back
							
							</router-link
						>
					</div>
					<div class="col-md-8">
						<img src="../../../public/images/monitoring-edit.svg" width="200" class="ms-4" alt="" />
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="text-center">
							<h4 class="p-0 m-0">Update monitoring</h4>
							<small>Update monitoring with settings that suits you</small>
							
						</div>

						<form v-if="monitoring" @submit.prevent="updateMonitoring" class="mt-4">
							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Name</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="monitoring.name"
										class="form-control"
										id="name"
										required
									/>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">URL</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="monitoring.url"
										class="form-control"
										id="name"
										required
									/>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Check interval</label>
								<div class="col-sm-6">
									<select v-model="monitoring.check_interval" class="form-select" required>
										<option disabled value="1">1 minutes (coming soon) (paid)</option>
										<option value="5">5 minutes</option>
										<option value="10">10 minutes</option>
										<option value="30">30 minutes</option>
										<option value="60">60 minutes</option>
									</select>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label">Check type</label>
								<div class="col-sm-6">
									<select v-model="monitoring.check_type" class="form-select" required>
										<option value="ping">Ping (0/5)</option>
										<option value="keyword">Keyword (0/3)</option>
									</select>
								</div>
							</div>

							<div class="mb-3 row" v-if="monitoring.check_type === 'website'">
								<label for="email" class="col-sm-3 col-form-label">Website search</label>
								<div class="col-sm-9">
									<input
										type="text"
										v-model="monitoring.website_search_string"
										class="form-control"
									/>
									<small>Enter string that you wanna search on page</small>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="email" class="col-sm-3 col-form-label pt-0">Notifications</label>
								<div class="col-sm-9">
									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="monitoring.email_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
										/>
										<label for="" class="form-check-label ms-2">Email</label>
									</div>

									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="monitoring.slack_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
											disabled
										/>
										<label for="" class="form-check-label ms-2"
											>Slack <small>(coming soon)</small></label
										>
									</div>

									<div class="form-check form-switch d-flex align-items-center mb-2">
										<input
											class="form-check-input"
											v-model="monitoring.sms_notification"
											type="checkbox"
											id="flexSwitchCheckDefault"
											disabled
										/>
										<label for="" class="form-check-label ms-2"
											>Text message <small>(coming soon)</small></label
										>
									</div>
								</div>
							</div>

							<div class="text-end mt-4">
								<button class="btn btn-primary" :disabled="loading">
									<font-awesome-icon 
										v-if="loading"
										:icon="['fas', 'spinner']" 
										class="me-1 fa-spin" 
									/> 
									Update
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'MonitoringUpdate',
	data() {
		return {
			loading: false,
			monitoring: null,
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},

	created() {
		this.getMonitoring()
	},
	methods: {
		// Get monitoring data
		getMonitoring() {
			restApi
				.get('/monitorings/' + this.$route.params.id)
				.then(({ data }) => {
					this.monitoring = data
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error),
						timer: 5000,
					})
				})
		},
		// Update monitoring data
		updateMonitoring() {
			this.loading = true
			restApi
				.put('/monitorings/' + this.$route.params.id, this.monitoring)
				.then(() => {
					this.loading = false
					this.$swal.fire({
						icon: 'success',
						title: 'Success',
						text: 'Monitoring successfully updated!',
					})
				})
				.catch(error => {
					this.loading = false
					this.$swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: handleErrors(error),
					})
				})
		},
	},
}
</script>
