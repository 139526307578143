<template>
	<div id="monitoringView">
		<div class="row justify-content-center">
			<div class="col-md-10">
				<!-- Monitoring page header -->
				<div class="monitoring-header">
					<div class="actions-left">
						<router-link class="btn btn-outline-primary" to="/monitoring"
							><font-awesome-icon :icon="['fas', 'arrow-left']" /> Back</router-link
						>
					</div>
					<div class="center-image">
						<img src="../../../public/images/monitoring-view.svg" width="200" alt="" />
					</div>
					<div class="actions-right">
						<router-link class="btn btn-primary" :to="'/monitoring/edit/' + $route.params.id">
							Edit</router-link
						>
					</div>
				</div>

				<div class="card">
					<div
						class="card-header d-flex justify-content-between align-items-center"
						:class="{
							'bg-success': monitoring.status === 'online',
							'bg-danger': monitoring.status === 'offline',
						}"
					>
						<span class="text-uppercase fw-bold text-white">{{ monitoring.status }} </span>
						<span class="text-white"
							>Last checked {{ monitoring.check_date }}

							<button class="btn btn-primary btn-sm ms-2" @click="checkNow(monitoring.url, monitoring.id)" :disabled="loadingCheck">
								<font-awesome-icon 
									:icon="['fas', 'sync']" 
									class="me-1" 
									:class="[{'fa-spin' : loadingCheck}]"
								/> 
								Check
							</button>
						</span>
					</div>
					<div class="card-body pt-3">
						<div class="row">
							<div class="col-md-12 d-flex justify-content-between mb-3"></div>
							<div class="col-md-4">
								<img
									src="../../../public/images/comming-soon.jpg"
									width="100%"
									alt="monitor.name"
									class="img-thumbnail"
								/>
							</div>
							<div class="col-md-8 ps-5">
								<p class="p-0 m-0"><b class="me-2">Name:</b> {{ monitoring.name }}</p>
								<p class="p-0 m-0">
									<b class="me-2">Url:</b> <a :href="monitoring.url">{{ monitoring.url }}</a>
								</p>
								<p class="p-0 m-0"><b class="me-2">IP address:</b> {{ monitoring.ip_address }}</p>
								<p class="p-0 m-0">
									<b class="me-2">Check interval:</b> {{ monitoring.check_interval }} minutes
								</p>
								<p class="p-0 m-0"><b class="me-2">Check type:</b> {{ monitoring.check_type }}</p>
								<p class="p-0 m-0"><b class="me-2">Last check:</b> {{ monitoring.last_check_at }}</p>
								<p class="p-0 m-0">
									<b class="me-2">Last offline:</b> {{ monitoring.last_offline_at || 'Never' }}
								</p>
								<code v-if="false">
									{{ monitoring }}
								</code>
							</div>
						</div>
					</div>
				</div>

				<div class="card mt-3" style="height:100px">
					<div class="card-body">
						test
					</div>
				</div>

				<div class="card mt-3" style="height:300px">
					<div class="card-body">
						test
					</div>
				</div>

				<div class="row mt-2 mb-4">
					<div class="col-md-12 d-flex justify-content-end">
						<button class="btn btn-outline-danger" @click="deleteMonitoring()">
							<font-awesome-icon :icon="['fas', 'times']" /> Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import restApi from '../../api'
import { mapState } from 'vuex'
import { handleErrors } from '../../actions/helpers'
export default {
	name: 'MonitoringView',
	data() {
		return {
			loading: false,
			loadingCheck: false,
			monitoring: {},
		}
	},
	computed: {
		...mapState(['account', 'storageUrl']),
	},

	created() {
		this.getMonitoring()
	},

	methods: {
		getMonitoring() {
			restApi
				.get('/monitorings/' + this.$route.params.id)
				.then(({ data }) => {
					this.monitoring = data
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: handleErrors(error),
						timer: 5000,
					})
				})
		},

		monitoringUpdate() {
			this.loading = true

			restApi
				.put('/monitoring/view/' + this.$router.query.id, this.monitoring)
				.then(() => {
					this.loading = false
					this.$swal.fire({
						icon: 'success',
						title: 'Success',
						text: 'Monitoring successfully updated!',
					})
				})
				.catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops..',
						text: handleErrors(error),
					})
				})
		},

		// Check now button
		checkNow(url, id) {
			this.loadingCheck = true
			restApi
				.post('/helpers/check', { url: url, id: id })
				.then(({ data }) => {
					this.loadingCheck = false
					this.monitoring = data.monitoring
				})
				.catch(error => {
					this.loadingCheck = false
					console.log(error)
				})
		},

		// Delete monitoring
		deleteMonitoring() {
			this.$swal
				.fire({
					title: 'Are you sure?',
					text: `You won't be able to revert this! `,
					icon: 'warning',
					position: 'center',
					showCancelButton: true,
					showConfirmButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!',
					toast: false,
					timer: 0,
				})
				.then(result => {
					if (result.isConfirmed) {
						restApi
							.delete(`/monitorings/` + this.monitoring.id)
							.then(() => {
								this.$router.push(`/monitoring`)
								this.$swal.fire({
									icon: 'success',
									title: `Monitoring ${this.monitoring.name} successfully deleted!`,
								})
							})
							.catch(error => {
								this.$swal.fire({
									icon: 'error',
									title: handleErrors(error, ''),
									timer: 6000,
								})
							})
					}
				})
		},
	},
}
</script>
